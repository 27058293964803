import { TTBackboneView } from '@/views/TTBackboneView';

export class PortalJobVacancyRowController extends TTBackboneView {
	constructor( options = {} ) {
		_.defaults( options, {} );

		super( options );
	}

	initialize( options ) {
		super.initialize( options );
		var $this = this;
		var row = Global.loadWidget( 'views/portal/hr/recruitment/PortalJobVacancyRow.html' );
		for ( var key in this.model.attributes ) {
			if ( this.model.get( key ) == false ) {
				this.model.set( key, '' );
			}
		}
		this.setElement( _.template( row )( this.model.toJSON() ) );
		this.$el.on( 'click', function( e ) {
			window.location = Global.getBaseURL( null, true, true ).replace(/#!m=.*?(&|$)/g, '#!m=PortalJobVacancyDetail&id=' + $this.model.id + '$1'); //Be sure to include any other URL args.
		} );
	}
}
