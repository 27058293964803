import { TTBackboneView } from '@/views/TTBackboneView';
import { TTAPI } from '@/services/TimeTrexClientAPI';
import 'bootstrap';

export class PortalForgotPasswordController extends TTBackboneView {
	constructor( options = {} ) {
		_.defaults( options, {
			events: {
				'click .send-reset-email': 'sendResetEmail',
				'click .close-reset-email': 'closeResetEmail'
			}
		} );

		super( options );
	}

	initialize( options ) {
		super.initialize( options );
		this.api = TTAPI.APIRecruitmentAuthentication;
		var el = Global.loadWidget( 'views/portal/sign_in/PortalForgotPassword.html' );
		this.setElement( _.template( el )() );
		this.emailInput = this.$( '#email' );
		this.render();
	}

	render() {
		var $this = this;
		this.emailInput.focus();
		this.emailInput.unbind( 'keydown' ).bind( 'keydown', function( e ) {
			if ( e.keyCode === 13 ) {
				$this.sendResetEmail();
			}
		} );
		$( 'body' ).append( this.$el );
		const modal = new bootstrap.Modal( this.$el[0], {
			backdrop: 'static'
		} );

		modal.show();
	}

	sendResetEmail() {
		var $this = this;
		var email = this.emailInput.val();
		this.api.resetPassword( email, {
			onResult: function( result ) {
				if ( !result.isValid() ) {
					$this.showErrorAlert( result );
				} else {
					var response = result.getResult();
					if ( response.hasOwnProperty( 'email_sent' ) ) { // make sure email if is sent successfully
						bootstrap.Modal.getInstance( $this.$el[0] ).hide();
						IndexViewController.instance.router.showTipModal( $.i18n._( 'An email has been sent to you with instructions on how to reset your password.' ) );
					}
				}
			}
		} );
	}

	closeResetEmail() {
		bootstrap.Modal.getInstance( this.$el[0] ).hide();
	}

	showErrorAlert( result ) {
		var details = result.getDetails();
		// if ( details.hasOwnProperty( 'error' ) ) {
		//
		// }
		if ( !details ) {
			details = result.getDescription(); // If the details is empty, try to get description to show.
		}
		var error_string = '';

		if ( Global.isArray( details ) || typeof details === 'object' ) {

			$.each( details, function( index, val ) {

				if ( val.hasOwnProperty( 'error' ) ) {
					val = val.error;
				}
				var i = 0;
				for ( var key in val ) {
					error_string = error_string + val[key];
					if ( i > 0 ) {
						error_string += '<br>';
					}
					i++;
				}
			} );
		} else {
			error_string = details;
		}
		this.emailInput.css( 'border', '1px solid red' );
		const tooltip = new bootstrap.Tooltip( this.emailInput[0], {
			title: error_string
		} );

		tooltip.show();
	}

}