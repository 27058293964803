class TTSAML {
	loginUser( user_name, url, new_window = false ) {
		Debug.Text( 'SAML Login Request', 'TTSAML.js', 'TTSAML', 'loginUser', 11 );
		if ( new_window ) {
			window.open( url, '_blank' );
		} else {
			window.location.href = url;
		}
	}

	logoutUser( user_name, url, new_window = false ) {
		Debug.Text( 'SAML Logout Request', 'TTSAML.js', 'TTSAML', 'logoutUser', 11 );
		if ( new_window ) {
			window.open( url, '_blank' );
		} else {
			window.location.href = url;
		}
	}
}

export const TTSAMLObj = new TTSAML();