<template>
    <div id="contentContainer" class="content-container">
        <div class="loading-view">
            <div class="progress-bar-div">
                <progress class="progress-bar" max="100" value="10">
                    <strong>Progress: 100% Complete.</strong>
                </progress>
                <span class="progress-label">Initializing...</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "LegacyView",
    components: {}
};
</script>

<style>
</style>