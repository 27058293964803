import { useRegistration } from '@web-auth/webauthn-helper';
import { useLogin } from '@web-auth/webauthn-helper';
import { Global } from '@/global/Global';

class TTWebauthn {

	enrollCurrentUser( authenticate_callback ) {
		this.register( {
			id: LocalCacheData.getLoginUser().id,
			username: LocalCacheData.getLoginUser().user_name,
			displayName: LocalCacheData.getLoginUser().full_name
		} )
			.then( ( result ) => {
				if ( result ) {
					Debug.Text( 'Webauthn enrollment success.', 'TTWebauthn.js', 'TTWebauthn', 'enrollCurrentUser', 10 );
					authenticate_callback();
				}
			} )
			.catch( ( error ) => Debug.Text( 'Webauthn enrollment failure.', 'TTWebauthn.js', 'TTWebauthn', 'enrollCurrentUser', 10 ) );
	}

	loginUser( id, user_name, authenticate_callback ) {
		this.login( {
			id: id,
			username: user_name,
		} )
			.then( ( result ) => {
				Debug.Text( 'Webauthn login success.', 'TTWebauthn.js', 'TTWebauthn', 'loginUser', 10 );
				if ( result.error ) {
					TAlertManager.showAlert( $.i18n._( result.error ) );
				}
				else if ( result ) {
					authenticate_callback( null, result.session_id );
				}
			} )
			.catch( ( error ) => Debug.Text( 'Webauthn login failure.', 'TTWebauthn.js', 'TTWebauthn', 'loginUser', 10 ) );
	}

	loginUserNameLess( authenticate_callback ) {
		this.login( {} )
			.then( ( result ) => {
				Debug.Text( 'Webauthn login success.', 'TTWebauthn.js', 'TTWebauthn', 'loginUserNameLess', 10 );
				if ( result ) {
					authenticate_callback( null, result.session_id );
				}
			} )
			.catch( ( error ) => Debug.Text( 'Webauthn login failure.', 'TTWebauthn.js', 'TTWebauthn', 'loginUserNameLess', 10 ) );
	}

	register = useRegistration( {
		actionUrl: Global.getBaseURL( '../../', false ) + '/api/webauthn/api.php?action=register',
		optionsUrl: Global.getBaseURL( '../../', false ) + '/api/webauthn/api.php?action=register_options',
	} );

	login = useLogin( {
		actionUrl: Global.getBaseURL( '../../', false ) + '/api/webauthn/api.php?action=login',
		optionsUrl: Global.getBaseURL( '../../', false ) + '/api/webauthn/api.php?action=login_options',
	} );
}

export const TTWebauthnObj = new TTWebauthn();