import { TTBackboneView } from '@/views/TTBackboneView';
import { TTAPI } from '@/services/TimeTrexClientAPI';
import { TTUUID } from '@/global/TTUUID';
import { ServiceCaller } from '@/services/ServiceCaller';

export class HeaderUploadResumeWidget extends TTBackboneView {
	constructor( options = {} ) {
		_.defaults( options, {} );

		super( options );
	}

	initialize( options ) {
		super.initialize( options );
		var tpl = Global.loadWidget( 'views/portal/header/HeaderUploadResumeWidget.html' );
		this.setElement( _.template( tpl )() );
		this.document_id = null;
		this.profileView = null;
		if ( options.profileView ) {
			this.profileView = options.profileView;
		}
		// this.render();
	}

	render() {
		var $this = this;
		if ( !this.uploader ) {
			this.uploader = this.$( '.register-resume' ).TImageBrowser( {
				field: 'file',
				name: 'file_data',
				accept_filter: '*',
				changeHandler: function( a ) {
					var file_name = $( this ).find( '.browser' )[0].files[0].name;
					$this.$( '#fileName' ).val( file_name );
				}
			} );
		}

		IndexViewController.instance.router.showFormModal( this.$el, {
			title: $.i18n._( 'Upload Resume' ),
			actions: [
				{ label: 'Close', isClose: true },
				{
					label: $.i18n._( 'Upload Resume' ), callBack: function( e ) {
						$this.uploadResume();
					}
				}
			]
		} );
	}

	uploadResume() {
		var $this = this;
		if ( !this.uploader ) {
			return false;
		}
		var uploader = this.uploader;
		var name = uploader.attr( 'file_name' );
		var file = uploader.getValue();
		if ( file ) {
			this.document_api = TTAPI.APIDocumentPortal;
			var document_name = 'Resume' + ' - ' + LocalCacheData.getPortalLoginUser().first_name + ' ' + LocalCacheData.getPortalLoginUser().last_name;
			var result = this.document_api.addAttachment( document_name, '', { async: false } ).getResult();
			this.document_id = result['document_id'];
			this.uploadFile( file, result['document_revision_id'] );
		}
	}

	uploadFile( file_data, document_revision_id ) {
		var $this = this;
		var url = ServiceCaller.getAPIURL( 'Class=' + this.document_api.className + '&Method=uploadAttachment' + '&v=2' );
		if ( LocalCacheData.getAllURLArgs() ) {
			if ( LocalCacheData.getAllURLArgs().hasOwnProperty( 'company_id' ) ) {
				url = url + '&company_id=' + LocalCacheData.getAllURLArgs().company_id;
			}
		}
		url = url + '&object_id=' + document_revision_id;
		var message_id = TTUUID.generateUUID();
		ProgressBar.showProgressBar( message_id );
		ProgressBar.changeProgressBarMessage( $.i18n._( 'File Uploading' ) + '...' );
		$.ajax( {
			url: url, //Server script to process data
			headers: {
				//Handle CSRF tokens and related headers here.
				'X-Client-ID': 'Browser-TimeTrex',
				'X-CSRF-Token': getCookie( 'CSRF-Token' ),
			},
			type: 'POST',
			data: file_data,
			success: function( res ) {
				ProgressBar.removeProgressBar();
				$this.uploaded();
				if ( res.error ) {
					$this.showUploadedMessage( res.error );
				} else {
					$this.showUploadedMessage( $.i18n._( 'Upload Successful!' ) );
					$this.refreshSubDocument();
				}
			},
			cache: false,
			contentType: false,
			processData: false
		} );
	}

	refreshSubDocument() {
		if ( this.profileView ) {
			this.profileView.addDocumentRow( this.document_id );
		}
	}

	uploaded() {
		this.uploader = null;
		IndexViewController.instance.router.hideFormModal();
	}

	showUploadedMessage( message ) {
		IndexViewController.instance.router.showTipModal( message );
	}
}